import {
    createWsClient,
    type CreateWsClientOptions,
    type WsListenOptions
} from '@core/app/utils/ws'
import { getPusherAuthApiService } from '@simploshop-services/PusherAuth.service'
import type { WsChannels, WsMessages, WsPrivateChannels, WsPrivateMessages } from '@core/types/ws'
import { hash } from 'ohash'

type UnifiedSocket = Awaited<ReturnType<typeof createWsClient>>

export type UseWsOptions = {
    /**
     * A function that will be called when the socket is loaded.
     *
     * !! NOTE: Do not use this function to listen to events.
     * Use the returned `listen` composable instead.
     * @param socket
     */
    onLoaded?: (socket: UnifiedSocket) => void
    /**
     * A function that will be called when the socket connection fails.
     */
    onError?: () => void
    auth?: boolean | {
        enabled: boolean,
        authErrorMessage?: string
    }
} & Pick<CreateWsClientOptions, 'broadcaster' | 'connection'>

export type WebSocketStatus = 'open' | 'closed' | 'error' | 'initial' | 'connecting'

type UseWsListenOptions<IsChannelPrivate extends boolean> = WsListenOptions<IsChannelPrivate>
& {
    /**
     * Whether to connect to the channel immediately after the socket is connected.
     * @default true
     */
    immediate: boolean
}

let clientsMap: Map<string, {
    client: Awaited<ReturnType<typeof createWsClient>>
    // map of channels to the number of listeners
    channels: Map<string, number>
}> | null = null
let creatingClientPromise: Promise<void> | undefined

export function useWs(options: UseWsOptions) {
    const status = ref<WebSocketStatus>('initial')

    let _client: ReturnType<typeof createWsClient> | null = null
    let clientKey = 'no-key'

    /**
     * A composable function to listen to a channel event in the websocket.
     * @param channel The channel to listen to.
     * @param event The event to listen to.
     * @param callback The callback to call when the event is received.
     * @param options Additional options for the listener.
     */
    function listen<
        C extends keyof WsChannels,
        E extends keyof WsMessages[C] & string,
        PC extends keyof WsPrivateChannels,
        PE extends keyof WsPrivateMessages[PC] & string,
        IsChannelPrivate extends boolean = false,
    >(
        channel: MaybeRefOrGetter<IsChannelPrivate extends true ? PC : C>,
        event: MaybeRefOrGetter<IsChannelPrivate extends true ? PE : E>,
        callback: (data: IsChannelPrivate extends true ? WsPrivateMessages[PC][PE] : WsMessages[C][E]) => void,
        options?: Partial<UseWsListenOptions<IsChannelPrivate>>
    ) {
        const status = ref<WebSocketStatus>('initial')
        const stopFunction = ref<((channel?: boolean) => void) | null>(null)

        let stopPromise = Promise.resolve()
        let currentChannel = channel

        async function connect(
            channel: IsChannelPrivate extends true ? PC : C,
            event: IsChannelPrivate extends true ? PE : E,
            options?: Partial<UseWsListenOptions<IsChannelPrivate>>
        ) {
            if (!_client) return

            stopPromise = _client.then((client) => {
                const clientMapEntry = clientsMap?.get(clientKey)

                stopFunction.value = client.listen(channel, event, callback, {
                    ...options,
                    onConnecting: () => {
                        status.value = 'connecting'
                        options?.onConnecting?.()
                    },
                    onSubscribe: () => {
                        status.value = 'open'
                        if (!clientMapEntry) {
                            errorLog('[useWs:listen]: FATAL ERROR - Client map entry not found.', clientKey)
                        } else {
                            // register the event subscription
                            clientMapEntry.channels.set(channel, (clientMapEntry.channels.get(channel) ?? 0) + 1)
                        }
                        options?.onSubscribe?.()
                    },
                    onSubscribeError: () => {
                        status.value = 'error'
                        options?.onSubscribeError?.()
                    },
                    onDisconnect: () => {
                        status.value = 'closed'
                        if (!clientMapEntry) {
                            errorLog('[useWs:listen]: FATAL ERROR - Client map entry not found.', clientKey)
                        } else {
                            // unregister the event subscription
                            const listeners = clientMapEntry.channels.get(channel) ?? 0
                            if (listeners > 1) {
                                clientMapEntry.channels.set(channel, listeners - 1)
                            } else {
                                clientMapEntry.channels.delete(channel)
                            }
                        }
                        options?.onDisconnect?.()
                    },
                })
            })
        }

        if (options?.immediate ?? true) {
            connect(toValue(channel), toValue(event), options)
        }

        /**
         * Reconnect to the channel in the websocket.
         * It is possible to reconnect to a different channel by providing the `ch` argument.
         * @param ch The channel to reconnect to. If not provided, the current channel is used.
         * @param opts Additional options
         */
        function reconnect<TC extends keyof WsChannels, TPC extends keyof WsPrivateChannels>(ch?: IsChannelPrivate extends true ? TPC : TC, opts?: Partial<{ skipIfConnected: boolean }>) {
            return new Promise<void>(resolve => {
                stopPromise = stopPromise.then(async () => {
                    // TODO: check if the channel is already connected and skip if needed

                    const newChannel = ch as (IsChannelPrivate extends true ? PC : C) | undefined ?? toValue(channel)
                    stopFunction.value?.(
                        // disconnect from the current channel only if the new channel is different
                        // and there are no other listeners on the current channel
                        newChannel !== currentChannel && (clientsMap?.get(clientKey)?.channels.get(currentChannel as string) ?? 0) <= 1
                    )
                    stopFunction.value = null

                    await connect(newChannel, toValue(event), options)
                    currentChannel = newChannel
                    resolve()
                })
            })
        }

        return {
            stop: async () => {
                await stopPromise
                if (!stopFunction.value) {
                    errorLog('[useWs:stop]: Trying to stop a listener that has not been started.')
                    return
                }

                stopFunction.value()
            },
            status: readonly(status),
            reconnect: reconnect,
            connect: () => reconnect(undefined, { skipIfConnected: true }),
        }
    }

    const result = {
        listen,
        status,
    }

    if (!import.meta.client) return result
    // CLIENT SIDE CODE ----------------------------------------------

    const { $i18n } = useNuxtApp()
    const { notifyError } = useNotifications()

    if (!clientsMap) {
        clientsMap = new Map()
    }

    const createWsClientOptions: CreateWsClientOptions = {
        broadcaster: options.broadcaster,
        connection: {
            host: options.connection.host,
            key: options.connection.key,
        },
        onConnecting: () => {
            status.value = 'connecting'
        },
        onConnect: () => {
            status.value = 'open'
        },
        onDisconnect: () => {
            status.value = 'closed'
        },
        onReconnecting: () => {
            status.value = 'connecting'
        },
        onError: () => {
            status.value = 'error'
            options.onError?.()
        },
        auth: {
            handler: async (info) => {
                try {
                    const response = await getPusherAuthApiService()
                        .post({
                            socket_id: info.socketId,
                            channel_name: info.channelName,
                        })

                    const model = response.getItem()
                    if (!model) {
                        errorLog('[useWs]: No model present in the authentication response.')
                        return null
                    }

                    return {
                        auth: model?.auth,
                        channel_data: model?.channelData,
                        shared_secret: model?.sharedSecret,
                    }
                } catch (e) {
                    if (e instanceof ApiResponseError) {
                        const errorMessage =
                            (typeof options.auth === 'object' ? options.auth.authErrorMessage : undefined)
                            ?? $i18n.t('_core_theme.errors.cant_authenticate_socket_connection')
                        notifyError(errorMessage)
                    }

                    errorLog('[useWs]: Error while fetching the authentication data.', {
                        host: options.connection.host,
                        socket_id: info.socketId,
                        channel_name: info.channelName,
                    }, e)
                }
                return null
            },
        },
    } as CreateWsClientOptions

    clientKey = hash(createWsClientOptions)

    _client = new Promise(async (resolve) => {
        if (creatingClientPromise) {
            await creatingClientPromise
            creatingClientPromise = undefined
        }

        const existingClient = clientsMap?.get(clientKey)
        if (existingClient) {
            resolve(existingClient.client)
            return
        }

        creatingClientPromise = createWsClient(createWsClientOptions).then((client) => {
            clientsMap?.set(clientKey, {
                client: client,
                channels: new Map(),
            })
            resolve(client)
        })
    })

    _client.then(socket => {
        options.onLoaded?.(socket)
    })

    return result
}
