import { CartPhase } from '@core-theme/app/assets/ts/enums/cart'

export default defineAppConfig({
    plugins: {
        sentry: {
            enabled: true,
        },
    },
    cart: {
        paymentReturnRouteName: 'cart-payment-summary',
        phases: [
            {
                id: CartPhase.DETAIL,
                icon: 'IconShoppingBag',
                label: 'cart.phases.cart',
                routeName: 'cart',
            },
            {
                id: CartPhase.SHIPPING_AND_PAYMENT,
                icon: 'IconPayment',
                label: 'cart.phases.shipping_and_payment',
                routeName: 'cart-shipping-and-payment',
            },
            {
                id: CartPhase.DELIVERY,
                icon: 'IconDelivery',
                label: 'cart.phases.address',
                routeName: 'cart-delivery',
            },
            {
                id: CartPhase.SUMMARY,
                icon: null,
                label: 'cart.send_order',
                hidden: true,
                routeName: 'cart-summary',
            },
        ],
    },
    userMenu: {
        links: [
            {
                labelPath: 'user.dashboard',
                routeName: 'customer-dashboard',
            },
            {
                labelPath: 'user.account_info',
                routeName: 'customer-account',
            },
            {
                labelPath: 'user.my_addresses',
                routeName: 'customer-my-addresses',
            },
            {
                labelPath: 'user.my_orders',
                routeName: 'customer-my-orders',
            },
            {
                isMobileOnly: true,
                labelPath: 'user.favorite_products',
                routeName: 'customer-favorite-products',
            },
        ],
    },
    links: {
        socials: {
            facebook: 'https://www.facebook.com/simplocz/',
            instagram: 'https://www.instagram.com/simplosro/',
            x: '',
            youtube: '',
        },
    },
})
